import Sidebar from './Sidebar';
import TopMenu from './TopMenu';
import Content from './Content';
import React, { useState } from 'react';
import './App.css';

function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [contentWrapperCollapsed, setContentWrapperCollapsed] = useState(false);

  function handleToggleSidebar() {
    setShowSidebar(prevShowSidebar => !prevShowSidebar);
    setContentWrapperCollapsed(prevContentWrapperCollapsed => !prevContentWrapperCollapsed);
  }

  return (
    <div className={`main-container ${showSidebar ? 'show-sidebar' : ''}`}>
      <TopMenu handleToggleSidebar={handleToggleSidebar} />
      <div className={`content-wrapper ${contentWrapperCollapsed ? "content-wrapper-collapsed" : ""}`}>
        <Sidebar show={showSidebar} />
        <Content />
      </div>
    </div>
  );
}

export default App;
