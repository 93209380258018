import React, { useRef, useEffect } from 'react';

function Content() {
  const cardContainerRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    const contentContainer = cardContainerRef.current.parentNode;
    const footer = footerRef.current;
    const sidebar = contentContainer.querySelector('.sidebar');
    const handleScroll = function() {
      const cardContainer = cardContainerRef.current;
      if (cardContainer.scrollTop === 0 || cardContainer.scrollHeight - cardContainer.scrollTop === cardContainer.clientHeight) {
        footer.classList.remove('hidden');
        contentContainer.classList.remove('expanded');
      } else {
        footer.classList.add('hidden');
        contentContainer.classList.add('expanded');
      }

      if (contentContainer.scrollTop > contentContainer.scrollHeight - window.innerHeight) {
        footer.classList.add('collapsed');
        contentContainer.classList.add('expanded');
        sidebar.style.height = sidebar.offsetHeight + footer.offsetHeight + 'px';
      } else {
        footer.classList.remove('collapsed');
        contentContainer.classList.remove('expanded');
        sidebar.style.height = '';
      }
    }

    cardContainerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      // eslint-disable-next-line
      cardContainerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="content">
      <div className="card-container" ref={cardContainerRef}>
        <div className="card">
          <h3>Card 1</h3>
          <p>Some text goes here.</p>
        </div>
        <div className="card">
          <h3>Card 2</h3>
          <p>Some more text goes here.</p>
        </div>
        <div className="card">
          <h3>Card 3</h3>
          <p>Even more text goes here.</p>
        </div>
        <div className="card">
          <h3>Card 4</h3>
          <p>Some text goes here.</p>
        </div>
        <div className="card">
          <h3>Card 5</h3>
          <p>Some more text goes here.</p>
        </div>
        <div className="card">
          <h3>Card 6</h3>
          <p>Even more text goes here.</p>
        </div>
      </div>
    </div>
  );
}

export default Content;
