import React from 'react';

function Sidebar(props) {
  return (
    <div className={`sidebar ${props.show ? "show" : ""}`}>
      <h3 className={`${props.show ? "show" : ""}`}>Menu</h3>
      <ul className={`${props.show ? "show" : ""}`}>
        <li>Home</li>
        <li>About</li>
        <li>Contact</li>
      </ul>
    </div>
  );
}

export default Sidebar;