import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube } from '@fortawesome/free-solid-svg-icons';


function TopMenu(props) {
  return (
    <div className="top-menu">
{/*       <div className="logo">
        <FontAwesomeIcon icon={faCube} />
      </div> */}
      <div className="toggle-button" onClick={props.handleToggleSidebar}>
      <FontAwesomeIcon icon={faCube} size='xl' />
      </div>
      <ul className="menu">
        <li><a href="/">Home</a></li>
        <li><a href="/">About</a></li>
        <li><a href="/">Contact</a></li>
      </ul>
    </div>
  );
}

export default TopMenu;
